import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import AfterHeader from '../components/AfterHeader';
import RedeemModal from '../components/Promos/RedeemModal';
import AlreadyRedeemedModal from '../components/Promos/AlreadyRedeemedModal';
import CodeModal from '../components/Promos/CodeModal';
import ErrorModal from '../components/Promos/ErrorModal';
import Slider from "react-slick";
import { getPromoById, cancel } from '../services/promos';
import { redeem } from '../services/redeem';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const PromoDetail = (props) => {
    const { id } = useParams();
    const [promo, setPromo] = useState({});
    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [redeemed, setRedeemed] = useState({});
    const [codeModal, setCodeModal] = useState(false);
    const [error, setError] = useState('Lo sentimos, no podemos procesar este canje en este momento.');
    const [errorModal, setErrorModal] = useState(false);

    if (!id) {
        props.history.push('/')
    }

    useEffect(() => {
        // GET PROMOS
        loadPromos();

        return cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPromos = async () => {
        try {
            const [singleId] = id.split('&');
            const p = await getPromoById(singleId);
            setPromo(p);
        } catch (err) {
            setPromo(false);
        }
    };

    useEffect(() => {
        if (promo && promo.description_title) {
            document.title = `Grisino Fan - Promoción ${promo.description_title}`;
        } else {
            document.title = 'Grisino Fan - Promoción';
        }
    }, [promo]);

    const Redeem = async (confirm) => {
        setError('');
        setCodeModal(false);
        setErrorModal(false);
        setModal(false);
        setConfirmModal(false);
        try {
            const r = await redeem(id, confirm);
            setRedeemed(r);

            if (r.redeemed) {
                props.setPoints(props.loyalty.points - r.pointsSubstracted);
                return setCodeModal(true);
            }

            if (r.reason && r.reason === 'Previously redeemed') {
                return setConfirmModal(true);
            }

            if (r.reason && r.reason === "Not enough points") {
                setError(`Te faltan ${r.pointsMissing} para acceder a este beneficio.`);
                props.setPoints(r.loyaltyPoints);
                return setErrorModal(true);
            }

            setError('Lo sentimos, no podemos procesar este canje en este momento.')
            setErrorModal(true);
        } catch (err) {
            setError('Lo sentimos, no podemos procesar este canje en este momento.')
            setErrorModal(true);
        }
    };

    const RedeemButton = () => {
        if (promo.points > props.loyalty.points) {
            return (

                <span className="btn btn-outline-danger btn-disabled">Te faltan {promo.points - props.loyalty.points} estrellas para acceder a este beneficio</span>
            );
        }

        return (<button className="btn btn-lg btn-primary" onClick={() => setModal(true)}>{promo.cta_text || 'Quiero mi beneficio'}</button>)
    };

    return (
        <>
            {
                promo ? (
                    <>
                        <RedeemModal show={modal} setShow={setModal} promo={promo} redeem={Redeem} />
                        <AlreadyRedeemedModal show={confirmModal} setShow={setConfirmModal} promo={promo} redeem={redeemed} confirm={Redeem} />
                        <CodeModal show={codeModal} setShow={setCodeModal} redeem={redeemed} promo={promo} />
                        <ErrorModal show={errorModal} setShow={setErrorModal} error={error} />
                        <AfterHeader text={promo.description_title} small={<NavLink to="/">&larr; Volver</NavLink>} button={RedeemButton} />
                        <div className="row w-100 p-5">
                            <div className="col-12 col-xl-4 col-xxl-3 mb-5">
                                <aside className="card rounded-12 mb-xl-4 shadow-dark-80">
                                    {
                                        promo.images && (
                                            <Slider {...settings}>
                                                {promo.images.map((img, i) => <img src={img.url} alt={img.name} className="rounded-12 " key={i} />)}
                                            </Slider>
                                        )
                                    }
                                </aside>
                            </div>
                            <div className="col-12 col-xl-8 col-xxl-9">
                                <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                                    <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                                        <h3 className="card-header-title my-2 ps-md-3 font-weight-semibold">Detalle</h3>
                                    </div>
                                    <div className="card-body px-0 p-md-4">
                                        <div className="px-3">
                                            <span dangerouslySetInnerHTML={{ __html: promo.description_text }} />
                                            {promo.points > 0 && <p><b>Puntos:</b> {promo.points}</p>}
                                        </div>
                                    </div>
                                </div>
                                {
                                    redeemed.redeemed
                                    && (
                                        <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
                                            <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                                                <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Tu Canje</h5>
                                            </div>
                                            <div className="card-body px-0 p-md-4">
                                                <div className="px-3">
                                                    {redeemed.pointsSubstracted > 0 && (<p>Usaste <b>{redeemed.pointsSubstracted}</b> Estrellas</p>)}
                                                    <p>Tu Código es <b>{redeemed.code}</b></p>
                                                    <p>{promo.more_info || 'Ya podés utilizarlo!'}</p>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <NavLink to="/mi-cuenta/cupones" className="btn btn-primary">Ver Mis Cupones</NavLink>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {promo.terms &&
                                <>
                                    <hr />
                                    <div className="px-3 terms">{promo.terms}</div>
                                </>
                            }
                        </div>
                    </>
                ) : (
                    <Redirect to="/404" />
                )
            }
        </>
    )
};

const mapStateToProps = (state) => ({
    loyalty: state.loyalty,
})

const mapDispatchToProps = (dispatch) => ({
    setPoints: (points) => dispatch({ type: 'POINTS', points })
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoDetail);
