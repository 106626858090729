import React, {useState, useEffect} from 'react';
import { getMissions, cancel } from '../../services/missions';

const Missions = () => {
    const [missions, setMissions] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const total = completed.length + missions.length;
        if (total !== 0 && completed.length !== 0) {
            setProgress((completed.length / total) * 100);
        }
    }, [completed, missions])

    useEffect(() => {
        req();
        
        return () => {
            cancel();
        }
    }, []);

    const req = async () => {
        const data = await getMissions();
        setMissions(data.missions);
        setCompleted(data.completed);
    }

    return (
        <div className="col-12 col-xl-8 col-xxl-9 mb-3">
            {
                missions.length > 0 && (
                    <div className="col-12 mb-3">
                        <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                            <h3 className="font-weight-semibold pb-xl-4">Sumá más Estrellas</h3>
                        </div>
                        <div className="row">
                            {
                                missions.map(mission => (
                                    <div className="col-md-6 col-12" key={mission._id}>
                                        <div className="card mb-4 rounded-12 shadow border border-gray-50">
                                            <div className="card-body p-3 px-xxl-4">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span className="badge badge-size-xl rounded-24 py-2 bg-secondary text-white d-flex flex-column">
                                                            <span className="fw-normal text-uppercase">Sumá</span>
                                                            <span className="fs-3">{mission.points}</span>
                                                            <span className="fw-normal text-uppercase">Estrellas</span>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="h5 mb-0">{mission.title}</span>
                                                        <span className="caption text-gray-600 d-block mb-1">{mission.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                completed.length > 0 && (
                    <div className="col-12 mb-3">
                        <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                            <h3 className="font-weight-semibold pb-xl-4">Misiones completadas</h3>
                        </div>
                        <div className="progress mb-4 mb-xl-5">
                            <div className="progress-bar text-white fw-bold" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                        </div>
                        <div className="row">
                            {
                                completed.map(mission => (
                                    <div className="col-md-6 col-12" key={mission._id}>
                                        <div className="card mb-4 rounded-12 shadow border border-gray-50">
                                            <div className="card-body p-3 px-xxl-4">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span className="badge badge-size-xl rounded-24 py-2 bg-secondary text-white d-flex flex-column">
                                                            <span className="fw-normal text-uppercase">Sumaste</span>
                                                            <span className="fs-3">{mission.points}</span>
                                                            <span className="fw-normal text-uppercase">Estrellas</span>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="h5 mb-0">{mission.mission.title}</span>
                                                        <span className="caption text-gray-600 d-block mb-1">{mission.mission.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default Missions;
